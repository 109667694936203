import PropTypes from 'prop-types';
import { forwardRef } from 'react';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { useTheme } from '@mui/material/styles';
import { Box, Link } from '@mui/material';

// ----------------------------------------------------------------------

const Logo = forwardRef(({ disabledLink = false, sx, ...other }, ref) => {
  const theme = useTheme();

  const PRIMARY_LIGHT = theme.palette.primary.dark;

  const PRIMARY_MAIN = theme.palette.primary.dark;

  const PRIMARY_DARK = theme.palette.primary.dark;

  // OR using local (public folder)
  // -------------------------------------------------------
  // const logo = (
  //   <Box
  //     component="img"
  //     src="/logo/logo_single.svg" => your path
  //     sx={{ width: 40, height: 40, cursor: 'pointer', ...sx }}
  //   />
  // );

  const logo = (
    <Box
      ref={ref}
      component="div"
      sx={{
        width: 135,
        height: 56,
        display: 'inline-flex',
        ...sx,
      }}
      {...other}
    >
    <svg width="135" height="56" viewBox="0 0 135 56" fill="none" xmlns="https://www.w3.org/2000/svg">
      <path d="M4.0793 23.8716C2.80289 23.8716 1.80472 23.5046 1.08262 22.7717C0.360511 22.0388 0 21.0003 0 19.6573V0L4.01542 0.00876382V19.5324C4.01542 20.297 4.32938 20.6793 4.95622 20.6793C5.27018 20.6793 6.21097 20.0078 6.52493 19.9048L7.37153 22.6939C6.53467 23.0653 5.01901 23.8716 4.07713 23.8716H4.0793Z" fill="#1A2D4D"/>
      <path d="M19.59 13.3495C19.59 10.3862 18.5128 8.90404 16.3584 8.90404C15.3754 8.90404 14.6067 9.27212 14.0524 10.0072C13.4981 10.7434 13.169 11.921 13.064 13.5412H19.59V13.3495ZM23.5751 14.5326C23.5751 14.7462 23.5437 15.3005 23.4809 16.1955H13.064C13.1896 17.8377 13.6075 19.0098 14.3188 19.7131C15.03 20.4164 15.9708 20.7681 17.1422 20.7681C17.8741 20.7681 18.5648 20.6454 19.2133 20.4C19.8618 20.1546 20.5514 19.7767 21.2843 19.2651L22.9472 21.5995C21.1068 23.1135 19.0563 23.8705 16.7979 23.8705C14.2668 23.8705 12.317 23.0817 10.9464 21.5042C9.57581 19.9267 8.8916 17.773 8.8916 15.0442C8.8916 13.3166 9.18391 11.7654 9.7696 10.3906C10.3553 9.01578 11.2019 7.93893 12.3105 7.16004C13.4191 6.38225 14.7366 5.99226 16.2631 5.99226C18.5853 5.99226 20.3836 6.74376 21.66 8.24676C22.9364 9.74975 23.574 11.8443 23.574 14.5315L23.5751 14.5326Z" fill="#1A2D4D"/>
      <path d="M32.2761 5.99336C34.5139 5.99336 36.4799 6.66489 38.1742 8.00795L36.6055 10.407C35.183 9.49013 33.7918 9.03222 32.4331 9.03222C31.6385 9.03222 31.0214 9.18668 30.5819 9.49561C30.1423 9.80453 29.9225 10.2153 29.9225 10.7269C29.9225 11.1103 30.0167 11.4302 30.2051 11.6866C30.3935 11.9418 30.7334 12.1828 31.2249 12.4063C31.7164 12.6298 32.4429 12.8806 33.4053 13.1578C35.2046 13.6475 36.5373 14.2982 37.4056 15.1088C38.2738 15.9195 38.708 17.0489 38.708 18.4993C38.708 20.1842 38.0389 21.4999 36.6997 22.4496C35.3616 23.3983 33.6987 23.8727 31.711 23.8727C30.3513 23.8727 29.1117 23.659 27.9933 23.2329C26.8739 22.8068 25.9071 22.2207 25.0908 21.4736L27.0991 19.1709C28.5422 20.2795 30.0481 20.8338 31.6168 20.8338C32.5165 20.8338 33.2321 20.653 33.7658 20.2904C34.2996 19.9278 34.5659 19.4272 34.5659 18.7874C34.5659 18.2967 34.4663 17.9078 34.2682 17.6197C34.069 17.3316 33.7139 17.0763 33.2018 16.8517C32.6886 16.6283 31.8897 16.3555 30.8016 16.0356C29.1073 15.5667 27.8634 14.9106 27.0677 14.0692C26.273 13.2268 25.8757 12.1773 25.8757 10.9186C25.8757 9.9809 26.142 9.13848 26.6758 8.39246C27.2095 7.64644 27.9619 7.06036 28.9352 6.63312C29.9074 6.20698 31.0214 5.99336 32.2772 5.99336H32.2761Z" fill="#1A2D4D"/>
      <path d="M53.8431 27.9358H0.0576172V32H53.8431V27.9358Z" fill="#F50F57"/>
      <path d="M47.41 5.99336C49.6478 5.99336 51.6149 6.66489 53.3081 8.00795L51.7394 10.407C50.3169 9.49013 48.9257 9.03222 47.5659 9.03222C46.7713 9.03222 46.1542 9.18668 45.7147 9.49561C45.2751 9.80453 45.0554 10.2153 45.0554 10.7269C45.0554 11.1103 45.1495 11.4302 45.3379 11.6866C45.5263 11.9418 45.8662 12.1828 46.3577 12.4063C46.8492 12.6298 47.5757 12.8806 48.5381 13.1578C50.3364 13.6475 51.6701 14.2982 52.5384 15.1088C53.4067 15.9195 53.8408 17.0489 53.8408 18.4993C53.8408 20.1842 53.1717 21.4999 51.8325 22.4496C50.4933 23.3983 48.8304 23.8727 46.8438 23.8727C45.4841 23.8727 44.2445 23.659 43.1261 23.2329C42.0067 22.8068 41.0399 22.2207 40.2236 21.4736L42.2319 19.1709C43.675 20.2795 45.1809 20.8338 46.7496 20.8338C47.6493 20.8338 48.366 20.653 48.8986 20.2904C49.4324 19.9278 49.6987 19.4272 49.6987 18.7874C49.6987 18.2967 49.5991 17.9078 49.401 17.6197C49.2029 17.3316 48.8467 17.0763 48.3346 16.8517C47.8225 16.6283 47.0225 16.3555 45.9344 16.0356C44.2401 15.5667 42.9951 14.9106 42.2005 14.0692C41.4059 13.2268 41.0085 12.1773 41.0085 10.9186C41.0085 9.9809 41.2749 9.13848 41.8086 8.39246C42.3423 7.64644 43.0947 7.06036 44.068 6.63312C45.0402 6.20698 46.1542 5.99336 47.409 5.99336H47.41Z" fill="#1A2D4D"/>
      <path d="M60.413 5.99336H56.3965V23.8716H60.413V5.99336Z" fill="#1A2D4D"/>
      <path  d="M58.3926 0C58.9902 0 59.4763 0.191709 59.8509 0.575126C60.2244 0.958543 60.4117 1.43179 60.4117 1.99486C60.4117 2.55794 60.2244 3.0268 59.8509 3.40146C59.4774 3.77611 58.9913 3.96453 58.3926 3.96453C57.7939 3.96453 57.3327 3.77721 56.9592 3.40146C56.5857 3.02571 56.3984 2.55794 56.3984 1.99486C56.3984 1.43179 56.5857 0.959639 56.9592 0.575126C57.3327 0.191709 57.8113 0 58.3926 0Z" fill="#1A2D4D"/>
      <path d="M72.2934 5.99336C73.7788 5.99336 74.9339 6.45894 75.76 7.3901C76.586 8.32125 76.9996 9.61939 76.9996 11.2845V23.8716H72.9831V11.9418C72.9831 10.9121 72.8109 10.189 72.4656 9.77276C72.1202 9.35648 71.6135 9.14834 70.9445 9.14834C70.2754 9.14834 69.6324 9.37291 69.0781 9.82206C68.5238 10.2712 67.9846 10.923 67.4628 11.7775V23.8716H63.4463V6.48633H66.9291L67.243 8.75397C68.6028 6.91356 70.2863 5.99336 72.2945 5.99336H72.2934Z" fill="#1A2D4D"/>
    </svg>

    </Box>
  );

  if (disabledLink) {
    return <>{logo}</>;
  }

  return (
    <Link to="/" component={RouterLink} sx={{ display: 'contents' }}>
      {logo}
    </Link>
  );
});

Logo.propTypes = {
  sx: PropTypes.object,
  disabledLink: PropTypes.bool,
};

export default Logo;
